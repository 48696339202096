@import "scss/variables";
@import "scss/mixins";
@import "scss/reset.scss";
@import "@progress/kendo-theme-material/dist/all.scss";

body {
  &.kendo-dialog-open {
    overflow: hidden;
  }
}
.intake-page {
  background: #f5f5f5;
  min-height: calc(100vh - 80px);
}
.intake-container {
  margin: 0 auto;
  max-width: 690px;
  padding-top: 47px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  padding-bottom: 48px;
}
.intake-card {
  background: #fff;
  border-radius: 4px;
  padding: 48px 114px;
  margin-bottom: 24px;
  p {
    font-size: 14px;
  }
}
.left-search-icon {
  position: relative;
  .fa:not(.fa-exclamation-circle) {
    position: absolute;
    left: 15px;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(1, 24, 98, 0.6);
  }
  .k-input-inner {
    padding-left: 35px;
  }
  &.multi {
    .k-input-inner {
      padding-left: 0px;
    }
  }
  .k-chip-list {
    padding-left: 40px;
  }
}

.numeric-text-suffix {
  position: relative;
  i {
    position: absolute;
    left: 1px;
    top: 1px;
    bottom: 1px;
    border-right: 1px solid rgba(1, 24, 98, 0.24);
    overflow: hidden;
    z-index: 1;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    border-radius: 4px 0px 0px 4px;
    background: linear-gradient(
        0deg,
        rgba(1, 24, 98, 0.08),
        rgba(1, 24, 98, 0.08)
      ),
      #ffffff;
    color: rgba(1, 24, 98, 0.6);
  }
  .k-input-spinner {
    display: none;
  }
}
.numeric-text-suffix:has(i) {
  .k-input-inner {
    padding-left: 58px;
  }
}
.numeric-text-suffix > i + .k-numerictextbox input {
  padding-left: 58px;
}

kendo-gridlayout-item, .kendo-field {
  margin-bottom: 16px;
  kendo-formfield,
  .kendo-formfield {
    &.k-form-field-error .ng-invalid {
      &.k-timepicker {
        border-top-color: #f31700 !important;
        border-width: 1px;
        border-style: solid;
        box-shadow: none;
        &:hover {
          border-top-color: #f31700 !important;
          border-color: #f31700;
        }
      }
      border-color: #f31700;
      border-width: 1px;
      border-style: solid;
      box-shadow: none;
    }
    kendo-textbox,
    kendo-textarea,
    kendo-dropdownlist,
    kendo-datepicker,
    kendo-timepicker,
    kendo-maskedtextbox,
    kendo-autocomplete,
    kendo-multiselect,
    kendo-dateinput,
    .k-input-solid,
    kendo-numerictextbox {
      &.k-timepicker {
        border-top-color: rgba(0, 0, 0, 0.38) !important;
        &:hover {
          border-top-color: rgba(1, 24, 98, 0.74) !important;
        }
        &.k-input-solid {
          &::after {
            border-width: 0 !important;
          }
        }
      }
      ::after {
        border-width: 0 !important;
        border-style: none;
        inset: auto !important;
        transition: none !important;
        transform: none !important;
        content: unset;
      }
      .k-input-inner:not(.k-disabled) {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $content;
      }
      &:hover {
        border-color: rgba(1, 24, 98, 0.74);
        border-width: 1px;
        border-style: solid;
        box-shadow: none;
      }
      &.k-picker-outline,
      &.k-timepicker,
      &.k-input-outline {
        border: 1px solid rgba(1, 24, 98, 0.24);
        border-top-color: rgba(0, 0, 0, 0.38);
        border-radius: 4px !important;
        border-color: rgba(0, 0, 0, 0.38);
        color: rgba(0, 0, 0, 0.87);
        min-height: 40px;
        overflow: inherit;
        background: linear-gradient(
            0deg,
            rgba(1, 24, 98, 0.04),
            rgba(1, 24, 98, 0.04)
          ),
          #ffffff !important;
        &:hover,
        &.k-hover {
          border-color: rgba(1, 24, 98, 0.74);
          border-top-color: rgba(1, 24, 98, 0.74);
        }
      }
      .k-input-value-text {
        .placeholder {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: rgba(1, 24, 98, 0.4);
        }
        &.not-placeholder:not(.k-disabled) {
          color: $content;
        }
      }
      &.warning-field {
        border: 1px solid #ffc000;
      }
      kendo-popup {
        .k-searchbox {
          overflow: inherit;
        }
        .k-list-item {
          gap: 0px;
          color: $content;
          font-size: 14px;
          font-weight: 500;
          &.k-selected {
            color: $kendo-color-primary;
          }
        }
        .nodata {
          .k-no-data,
          .k-nodata {
            min-height: 0;
          }
        }
      }
      kendo-taglist {
        .k-chip-outline {
          background: linear-gradient(
              0deg,
              rgba(1, 24, 98, 0.16),
              rgba(1, 24, 98, 0.16)
            ),
            #ffffff;
          border-color: transparent;
        }
        .k-chip-outline-base {
          color: rgba(1, 24, 98, 0.74);
          font-weight: 600;
          font-size: 14px;
        }
        .k-chip-actions {
          margin-left: 4px;
        }
        .k-i-x-circle::before {
          content: "\f057";
          font-family: "Font Awesome 6 Free";
          font-style: normal;
          color: $content;
          font-weight: 900;
        }
      }
      &.k-input-outline.k-focus:after,
      &.k-picker-outline.k-focus:after {
        opacity: 0;
      }
      &.min-height-96 {
        .k-input-inner {
          min-height: 96px;
          padding-inline: 12px;
        }
      }
      &.k-focus {
        border-color: rgba(1, 24, 98, 0.24);
        border-width: 1px !important;
        border-style: solid;
        box-shadow: 0 0 0 1px $kendo-color-primary,
          0 0 0 5px rgba(33, 150, 243, 0.25);
      }
      &.k-disabled {
        border-color: rgba(1, 24, 98, 0.24);
        border-width: 1px;
        border-style: solid;
        box-shadow: none;
        .k-input-inner {
          color: rgba(1, 24, 98, 0.4);
        }
      }
      ::placeholder,
      .k-input-inner:not(.k-disabled)::placeholder {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: rgba(1, 24, 98, 0.4);
      }
    }

    /* START warning message */
    .warning-field-message {
      i {
        color: rgba(255, 192, 0, 1);
      }
      span {
        margin-left: 4px;
        color: rgba(1, 24, 98, 0.6);
        font-weight: 400;
        font-size: 12px;
      }
    }
    /* END warning message */

    .asterisk .k-label::after {
      content: "*";
      margin-left: 5px;
      color: #f31700;
    }
    .k-form-error {
      align-items: center;
      .k-icon {
        margin-right: 4px;
      }
    }
  }
}

.btn-primary {
  width: 100%;
  background: $kendo-color-primary;
  border-radius: 4px;
  padding: 10px 16px;
  text-transform: none;
  border: none;
}
.password-strength {
  margin-bottom: 16px;
  padding-left: 25px;
  li {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $content;
    margin-bottom: 4px;
  }
}
.icon-input {
  position: relative;
}
.eye-img {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 1;
  margin-top: -8px;
  width: 20px;
}

/* Anchor tag */
a {
  &.kendo-anchor-tag {
    color: $kendo-color-primary;
    text-decoration: none;
    background-color: transparent;
    text-decoration: underline;
    i {
      text-decoration: none;
    }
    &:hover {
      color: $kendo-color-primary;
      text-decoration: none;
      background-color: transparent;
      text-decoration: underline;
    }
    &:focus {
      border: 1px solid rgba(1, 24, 98, 0.32);
      box-shadow: 0 0 0 1px $kendo-color-primary inset,
        0 0 0 4px rgba(33, 150, 243, 0.25);
    }
  }
}
/* Anchor tag */

/* Kendo List View */
kendo-listview {
  .k-listview-header {
    border-bottom: none;
  }
  &.carrier-list {
    border-radius: 4px;
    border: 1px solid rgba(1, 24, 98, 0.12);
    color: rgba(1, 24, 98, 0.9);
    .header {
      font-weight: 800;
      font-size: 14px;
      line-height: 16px;
      padding: 12px 16px;
    }
    .content-details {
      padding: 12px 16px;
      border-top: 1px solid rgba(1, 24, 98, 0.12);
      .content {
        color: $content;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
      }
      .sub-content {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
      }
    }
    .footer {
      background: linear-gradient(
          0deg,
          rgba(1, 24, 98, 0.04),
          rgba(1, 24, 98, 0.04)
        ),
        #ffffff;
      padding: 12px 16px;
    }
  }
}
/* Kendo List View */

/* kendo FormHint */
kendo-formhint {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(1, 24, 98, 0.6);
}
/* kendo FormHint */

/* Lable Error */
kendo-label {
  &.font-weight-600 {
    label {
      line-height: 20px;
      color: $content;
    }
  }
  &.checkbox-margin {
    label {
      margin-left: 12px;
      margin-bottom: 0px;
      color: rgba(1, 24, 98, 0.74);
    }
  }
  &.mobile-color {
    label {
      color: rgba(1, 24, 98, 1);
    }
  }
  .k-label {
    margin-bottom: 4px;
  }
  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: rgba(1, 24, 98, 0.6);
  }
  .tooltip-with-icon {
    float: right;
    cursor: pointer;
    .fa {
      font-size: 16px;
      padding: 2px;
      font-weight: 900;
      color: $content;
    }
  }
  &.k-checkbox-filter-label {
    label {
      font-weight: 600;
      line-height: 20px;
      color: rgba(1, 24, 98, 0.74);
    }
  }
}
.filter-checkboxes {
  kendo-label {
    .k-label {
      margin-bottom: 0px;
    }
  }
}

.k-form-error {
  i {
    width: 16px;
    font-size: 16px;
    height: 16px;
    color: #f31700;
  }
  span {
    color: rgba(1, 24, 98, 0.6);
    margin-left: 5px;
  }
  .k-text-error,
  .k-form-field-error .k-label {
    color: rgba(1, 24, 98, 0.6);
  }
}
.k-form-error,
.k-text-error,
.k-form-field-error .k-label {
  color: rgba(1, 24, 98, 0.6);
}
/* Lable Error */

/* Kendo tooltip */
kendo-tooltip {
  width: 300px;
  background-color: #1d2338 !important;
  border-radius: 4px;
  box-shadow: none !important;
  .k-callout {
    color: #1d2338 !important;
  }
}
.tooltipwidth200 {
  kendo-tooltip {
    width: 200px;
  }
}
.tooltipwidth300 {
  kendo-tooltip {
    width: 300px;
  }
}
.tooltipwidthauto {
  kendo-tooltip {
    width: auto;
  }
}
.tooltipwidth160 {
  kendo-tooltip {
    width: 160px;
  }
}
.tooltipwidth140 {
  kendo-tooltip {
    width: 140px;
  }
}
/* Kendo tooltip */

.font-weight-600,
.font-weight-600 > .k-label {
  font-weight: 600;
}

.font-weight-500,
.font-weight-500 > .k-label {
  font-weight: 500;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}
.pl-24 {
  padding-left: 24px !important;
}

.pr-24 {
  padding-right: 24px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pl-12 {
  padding-left: 12px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pt-16 {
  padding-top: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.mb-15,
.mb-15 > .k-label {
  margin-bottom: 15px;
}

.ml-24 {
  margin-left: 24px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mt-8 {
  margin-top: 8px !important;
}
.mt-30 {
  margin-top: 30px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}
.mb-14 {
  margin-bottom: 14px !important;
}
.mb-4 {
  margin-bottom: 4px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.m-24 {
  margin: 24px;
}
.m-16 {
  margin: 16px;
}
.p-24 {
  padding: 24px;
}
.p-16 {
  padding: 16px;
}

.text-transform-lowercase {
  text-transform: lowercase;
}
.cursor {
  cursor: pointer;
}
.nocursor {
  pointer-events: none;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-decoration {
  text-decoration: underline !important;
  -webkit-text-decoration: underline !important;
}
.text-line-through {
  text-decoration: line-through $kendo-color-error 2px !important;
  -webkit-text-decoration: line-through !important;
}
.text-overfloe-unset {
  text-overflow: unset !important;
}
.font-weight600 {
  font-weight: 600 !important;
}
.dflex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.line-height16 {
  line-height: 16px !important;
}
.danger-color {
  color: $kendo-color-error;
}
.word-break {
  word-break: break-word;
}
.kendo-primary-color {
  color: $kendo-color-primary;
}
.border-radius {
  border-radius: 4px !important;
}
.visibility-hidden {
  visibility: hidden;
}
input[type="radio"],
input[type="radio"],
input[type="checkbox"],
input[type="checkbox"] {
  &:focus {
    box-shadow: 0 0 0 1px #2196f3, 0 0 0 5px rgba(33, 150, 243, 0.25);
  }
}
/* Radio Field */
.kendo-radio {
  .k-radio {
    margin-right: 8px;
  }
  .k-radio-label {
    font-weight: 600;
    font-size: 14px;
    color: rgba(1, 24, 98, 0.74);
  }
}
/* Radio Field */

/* START Grid Action Item */
.grid-action-items {
  &.k-button-md.k-icon-button {
    padding: 0;
  }
}
.grid-action-items-calendar {
  &.k-button-md.k-icon-button {
    padding: 0;
    color: $content;
    font-weight: 900;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
  }
}

.grid-action-items-menu {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  .k-menu-group,
  .k-context-menu {
    .k-menu-item {
      &:focus,
      &.k-focus {
        background: #ffffff;
      }
    }
  }
  .k-menu-group-md .k-menu-link {
    padding-block: 12px;
    padding-inline: 16px;
    border-bottom: 1px rgba(1, 24, 98, 0.08) solid;
  }
  .k-menu-link-text {
    color: $content;
    font-weight: 500;
    font-size: 14px;
  }
}
/* END Grid Action Item */

/*Button styling*/
.k-button-link-primary {
  color: $kendo-color-primary;
  box-shadow: none;
  text-transform: none;
  padding: 0;
  &:hover {
    text-decoration: none;
    background: linear-gradient(
        0deg,
        rgba(1, 24, 98, 0.08),
        rgba(1, 24, 98, 0.08)
      ),
      #ffffff;
    color: $content;
    .k-button-icon {
      color: $kendo-color-primary;
    }
  }
  &:active {
    color: #011862;
  }
  &:focus {
    color: $kendo-color-primary;
    border-color: $kendo-color-primary;
    box-shadow: 0 0 0 1px $kendo-color-primary inset,
      0 0 0 4px rgba(33, 150, 243, 0.25);
  }
}

.k-button-link-secondary {
  color: $kendo-color-secondary;
  box-shadow: none;
  text-transform: none;
  padding: 0;
  &:hover {
    text-decoration: none;
    background: linear-gradient(
        0deg,
        rgba(1, 24, 98, 0.08),
        rgba(1, 24, 98, 0.08)
      ),
      #ffffff;
    color: $content;
    .k-button-icon {
      color: $kendo-color-secondary;
    }
  }
  &:active {
    color: #011862;
  }
  &:focus {
    color: $kendo-color-primary;
    border-color: $kendo-color-primary;
    box-shadow: 0 0 0 1px $kendo-color-primary inset,
      0 0 0 4px rgba(33, 150, 243, 0.25);
  }
}

.k-button-link-error {
  color: $kendo-color-error;
  box-shadow: none;
  text-transform: none;
  padding: 0;
  &:hover {
    text-decoration: none;
    background: linear-gradient(
        0deg,
        rgba(1, 24, 98, 0.08),
        rgba(1, 24, 98, 0.08)
      ),
      #ffffff;
    color: $content;
    .k-button-icon {
      color: $kendo-color-error;
    }
  }
  &:active {
    color: #011862;
  }
  &:focus {
    color: $kendo-color-primary;
    border-color: $kendo-color-primary;
    box-shadow: 0 0 0 1px $kendo-color-primary inset,
      0 0 0 4px rgba(33, 150, 243, 0.25);
  }
}

.k-button-solid-primary {
  color: #ffffff;
  border: 1px solid rgba(1, 24, 98, 0.24);
  box-shadow: none;
  text-transform: none;
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(1, 24, 98, 0.08),
        rgba(1, 24, 98, 0.08)
      ),
      #2196f3;
  }
  &:active {
    background-color: #011862;
    box-shadow: none !important;
    border-color: transparent !important;
  }
  &:focus {
    border-color: $kendo-color-primary;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.12),
        rgba(255, 255, 255, 0.12)
      ),
      #2196f3;
    box-shadow: 0 0 0 1px $kendo-color-primary inset,
      0 0 0 4px rgba(33, 150, 243, 0.25);
  }
}

.k-button-solid-base {
  color: $content;
  border: 1px solid rgba(1, 24, 98, 0.32);
  box-shadow: none;
  text-transform: none;
  background: #ffffff;
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(1, 24, 98, 0.08),
        rgba(1, 24, 98, 0.08)
      ),
      #ffffff;
    border-radius: 4px;
  }
  &:active {
    border-color: $kendo-color-primary;
    box-shadow: 0 0 0 1px $kendo-color-primary inset,
      0 0 0 4px rgba(33, 150, 243, 0.25);
  }
  &:focus {
    border-color: $kendo-color-primary;
    box-shadow: 0 0 0 1px $kendo-color-primary inset,
      0 0 0 4px rgba(33, 150, 243, 0.25);
  }
}

.k-button-solid-light {
  color: $content;
  border: 1px solid rgba(1, 24, 98, 0.24);
  box-shadow: none;
  text-transform: none;
  background: linear-gradient(
      0deg,
      rgba(1, 24, 98, 0.08),
      rgba(1, 24, 98, 0.08)
    ),
    #ffffff;
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(1, 24, 98, 0.12),
        rgba(1, 24, 98, 0.12)
      ),
      #ffffff;
    border-color: rgba(1, 24, 98, 0.32);
  }
  &:focus {
    background: linear-gradient(
        0deg,
        rgba(1, 24, 98, 0.08),
        rgba(1, 24, 98, 0.08)
      ),
      #ffffff;
    border-color: $kendo-color-primary;
    box-shadow: 0 0 0 1px $kendo-color-primary inset,
      0 0 0 4px rgba(33, 150, 243, 0.25);
  }
}

.k-button-solid-error {
  color: #ffffff;

  box-shadow: none;
  text-transform: none;
  &:hover {
    background: #d21501;
  }
  &:focus {
    background: $kendo-color-error;
    border-color: $kendo-color-primary;
    box-shadow: 0 0 0 1px $kendo-color-primary inset,
      0 0 0 4px rgba(33, 150, 243, 0.25);
  }
}
/* Button styling */

/* Modal Style */
kendo-dialog {
  &.k-dialog-wrapper {
    z-index: 999;
    &.increase-z-index {
      z-index: 1001;
    }
  }
  &.right-position {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    > .k-window {
      > .k-window-content {
        min-height: calc(100vh - 79px);
        max-height: calc(100vh - 79px);
        background: rgba(1, 24, 98, 0.04);
      }
    }
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 7;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ccd1e0;
    border-radius: 10px;
  }
  kendo-dialog-titlebar {
    &.k-window-titlebar {
      border-width: 0px 0px 1px 0px;
      border-color: inherit;
      .k-dialog-title {
        align-items: center;
        i,
        .fa-circle-exclamation {
          color: #fec000;
          &.error {
            color: #f31700;
          }
        }
      }
    }
    .modal-title {
      span {
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        color: rgba(1, 24, 98, 0.9);
      }
      .modal-subtitle {
        display: block;
        color: rgba(1, 24, 98, 0.6);
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }
      div {
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        color: rgba(1, 24, 98, 0.9);
      }
    }
    &.hide-titlebar-actions {
      .k-window-titlebar-actions {
        display: none;
      }
    }
    .k-window-titlebar-actions {
      button {
        top: 0;
        right: 0;
        position: absolute;
      }
    }
  }
  .k-window-content {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
  }
  &.calendar-sidebar {
    .k-window-content {
      padding: 0;
    }
  }
  kendo-dialog-actions {
    &.k-dialog-actions {
      grid-gap: 0;
      gap: 0;
    }
  }
}

/* Modal Style */

kendo-badge.k-badge {
  padding-block: 2px;
  padding-inline: 8px;
  line-height: 16px;
  z-index: 0;
  &.k-badge-md {
    font-size: 12px;
    font-weight: 500;
    padding-block: 1px;
    padding-inline: 8px;
  }
  &.k-badge-sm {
    font-size: 12px;
    line-height: 14px;
    padding: 0.5px 8px;
  }
  &.k-badge-lg {
    font-size: 14px;
    line-height: 16px;
    padding: 3px 8px;
  }
  &.fw600 {
    font-weight: 500;
  }
  &.fcolor {
    color: $content !important;
  }
  &[themeColor="tertiary"],
  &[themeColor="warning"] {
    color: $content;
  }
}
.k-badge-solid-tertiary {
  border-color: transparent;
}
/* Grid Style */
.k-column-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(1, 24, 98, 0.6);
}
:root {
  --content-wrapper-padding-x: 1.5rem;
  --content-wrapper-padding-y: 1.5rem;
}
:root {
  --content-wrapper-padding-x1: 1rem;
}
.divider-line {
  margin: 0 calc(var(--content-wrapper-padding-x) * -1);
}
.divider-line1 {
  margin: 0 calc(var(--content-wrapper-padding-x1) * -1);
}
kendo-grid {
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom-color: rgba(1, 24, 98, 0.08) !important;
}
.k-grid-header {
  padding: 0 !important;
}
.k-grid-content {
  overflow: hidden;
}

.report-list {
  .k-grid-content {
    overflow: auto;
  }
}
// .k-grid-aria-root {
//   max-height: 600px;
// }
.k-grid-md .k-table-th {
  position: relative;
  padding-right: 0 !important;
  padding-left: 16px !important;
  border-left: none !important;
  border-right: none !important;
  border-color: rgba(1, 24, 98, 0.08) !important;
  padding-block: 12px;

  &.pl-0 {
    padding-left: 0 !important;
  }
  &.text-center {
    .k-cell-inner {
      justify-content: center;
    }
  }
  &.text-right {
    .k-cell-inner {
      justify-content: right;
      margin-inline: -12px;
    }
  }
  &:first-child {
    padding-left: 16px !important;
  }
  &:not(:first-child .k-hierarchy-cell) + .k-table-th {
    &:after {
      --th-border-height: 16px;
      content: "";
      position: absolute;
      left: 0;
      top: calc(50% - calc(var(--th-border-height) / 2));
      width: 1px;
      height: var(--th-border-height);
      background-color: rgba(1, 24, 98, 0.08) !important;
    }
  }
  &.remove-bar {
    &:after {
      display: none !important;
    }
  }
}
.k-grid {
  &.investigation-list,
  &.report-list {
    td {
      &.k-table-td {
        padding: 5px 15px 5px 15px !important;
        &:first-child {
          padding-left: 15px !important;
        }
      }
    }
  }
  &:not(.investigation-list) {
    td {
      &.k-table-td {
        padding: 12px 0 12px 15px;
        &:first-child {
          padding-left: 15px !important;
        }
      }
    }
  }
  &.td-p-0 {
    td {
      &.k-table-td {
        padding: 0px !important;
      }
    }
  }
  &.td-border-left {
    td {
      &.k-table-td {
        border-left: 1px solid rgba(1, 24, 98, 0.08) !important;
      }
    }
  }
  &.td-top-bottom-20 {
    td {
      &.k-table-td {
        padding: 20px 0px 20px 15px !important;
        &:first-child {
          padding-left: 15px !important;
        }
      }
    }
  }
  &.td-20-16 {
    td {
      &.k-table-td {
        padding: 20px 16px 20px 16px !important;
        &:first-child {
          padding-left: 16px !important;
        }
      }
    }
  }
  &.td-16-8 {
    td {
      &.k-table-td {
        padding: 8px 8px 8px 16px !important;
        &:first-child {
          padding-left: 16px !important;
        }
      }
    }
  }
  a {
    &.primary-color {
      color: $kendo-color-primary;
    }
  }
  &.rcheck {
    .k-table-th {
      input {
        display: none;
      }
      &:first-child {
        &:after {
          display: none;
        }
      }
    }
  }
  &.odd-even-background {
    th,
    td {
      &:nth-child(odd) {
        background-color: rgba(1, 24, 98, 0.04);
      }

      &:first-child {
        background-color: transparent;
      }
    }
  }
  &.last-col-background {
    th,
    td {
      &:last-child {
        background-color: rgba(1, 24, 98, 0.04);
      }
    }
  }
  &.border-none-tr-td {
    td {
      tr {
        td {
          border: none;
          padding-left: 0px !important;
          padding: 0px;
        }
      }
    }
  }
}

.k-grid-table {
  tr {
    td {
      border-left: none !important;
      border-right: none !important;
      border-color: rgba(1, 24, 98, 0.08) !important;
      color: $content;
      padding-left: 16px !important;
      line-height: 16px;
      .phone-number {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: rgba(1, 24, 98, 0.6);
      }
      .time {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: rgba(1, 24, 98, 0.6);
      }
    }
  }
  .id-column {
    overflow: unset;
    word-wrap: break-word;
    a {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .risk-type {
    display: flex;
    align-items: center;
  }
}

// expand raw icon over write
td {
  a {
    kendo-icon-wrapper {
      kendo-icon {
        &.k-i-plus:before {
          content: "\f078";
          @include fico;
          font-size: 12px;
        }
        &.k-i-minus:before {
          content: "\f077";
          @include fico;
          font-size: 12px;
        }
      }
    }
  }
}

.k-i-sort-asc-small::before {
  content: "\f0de";
  @include fico;
  color: $content;
  font-size: 12px;
}
.k-i-sort-desc-small::before {
  content: "\f0dd";
  @include fico;
  color: $content;
  font-size: 12px;
}
.sort-header {
  .k-column-title {
    &::after {
      content: "\f0dc";
      @include fico;
      color: $content;
      margin-left: 8px;
      font-size: 12px;
      opacity: 0;
    }
  }
  &.k-sorted {
    .k-column-title {
      &::after {
        display: none;
      }
    }
  }
  &:hover {
    .k-column-title {
      &::after {
        opacity: 1;
      }
    }
  }
}

.k-grid {
  /* START hide details row action */
  &.investigation-list,
  &.report-list,
  &.pending-request {
    .k-hierarchy-cell {
      width: 0;
    }

    .k-header.k-hierarchy-cell {
      border: none;
    }

    .k-hierarchy-cell .k-icon {
      display: none;
    }

    .k-hierarchy-col {
      width: 1px;
    }
  }
  /* END hide details row action */

  /* START to bill details row action */
  &.to-bill,
  &.bookkeeping-evidence-billing-list {
    .k-hierarchy-cell {
      width: 5px;
    }
    .k-hierarchy-col {
      width: 5px;
    }
  }
  /* END to bill details row action */
  col.k-sorted,
  .k-table-th.k-sorted {
    background: transparent;
  }
  &.detail-items {
    margin: 16px 50px;
  }
  .k-table-tbody {
    .k-table-row {
      &.k-detail-row {
        border-color: transparent;
        background: linear-gradient(
            0deg,
            rgba(1, 24, 98, 0.08),
            rgba(1, 24, 98, 0.08)
          ),
          #ffffff;
        .k-detail-cell {
          .k-grid-md td,
          .k-grid-md .k-table-td {
            padding: 20px 16px !important;
          }
        }
      }
      &:not(.k-detail-row) {
        &:hover {
          background-color: #ffffff;
        }
      }
    }
  }
  /* START - for all raw select checkbox */
  .checkbox-header {
    vertical-align: middle;
    display: flex;
  }
  /* END - for all raw select checkbox */
  .k-grid-footer-wrap {
    border-color: transparent;
  }
}

/* START Small device */
.k-pager-mobile-md .k-pager-info,
.k-pager-mobile-sm .k-pager-info,
.k-pager-mobile-sm .k-pager-sizes,
.k-pager-mobile-sm .k-pager-numbers {
  display: block;
}
/* END Small device */
//  border-width: 1px 0px 0px 0px;
.k-pager {
  color: rgba(1, 24, 98, 0.74);
  border-color: rgba(0, 0, 0, 0.12);
  padding-inline: 0px;
  &:focus {
    color: rgba(1, 24, 98, 0.74);
    background-color: transparent;
  }
  .k-pager-nav,
  .k-button {
    border-radius: 4px !important;
    margin-right: 2px;
    &.k-selected {
      &:before {
        opacity: 1;
      }
    }
    &:before,
    &:hover {
      border-color: rgba(33, 150, 243, 1) !important;
      border-style: solid;
      border-width: 1px;
      background: transparent !important;
    }
    &.k-disabled {
      opacity: 0.6;
    }
  }
  .k-pager-numbers {
    button {
      color: rgba(1, 24, 98, 0.6);
      &.k-selected {
        color: $content;
      }
    }
  }
  .k-pager-numbers-wrap {
    gap: 8px;
  }
  .k-pager-info {
    margin-right: 8px;
  }
  kendo-pager-prev-buttons,
  kendo-datapager-prev-buttons {
    .k-i-caret-alt-to-left {
      &:before {
        content: "\f100";
        font-family: "Font Awesome 6 Free";
        color: $content;
        font-weight: 900;
      }
    }
    .k-i-caret-alt-left {
      &:before {
        content: "\f104";
        @include fico;
        color: $content;
      }
    }
  }
  kendo-pager-next-buttons,
  kendo-datapager-next-buttons {
    .k-i-caret-alt-right {
      &:before {
        content: "\f105";
        @include fico;
        color: $content;
      }
    }
    .k-i-caret-alt-to-right {
      &:before {
        content: "\f101";
        @include fico;
        color: $content;
      }
    }
  }
  kendo-label {
    label {
      color: rgba(1, 24, 98, 0.74);
    }
  }

  kendo-pager-page-sizes,
  kendo-datapager-page-sizes {
    kendo-label {
      label::first-letter {
        text-transform: capitalize;
      }
    }
    kendo-dropdownlist {
      .k-input-inner {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $content;
        padding-inline: 14px;
      }

      &.k-picker-solid {
        border: 1px solid rgba(1, 24, 98, 0.24) !important;
        border-radius: 4px !important;
      }
      &.k-selected {
        &:before {
          opacity: 0;
        }
      }
      .k-button-solid-base {
        border: none !important;
        border-radius: 0px !important;
        &:hover {
          background: linear-gradient(
              0deg,
              rgba(1, 24, 98, 0.08),
              rgba(1, 24, 98, 0.08)
            ),
            #ffffff;
          border-radius: 4px;
        }
        &:active,
        &:focus {
          border-color: transparent;
          box-shadow: none;
        }
        .k-i-caret-alt-down {
          &:before {
            content: "\f0d7";
            font-family: "Font Awesome 6 Free";
            color: $content;
            font-weight: 900;
          }
        }
      }
      .k-button-icon {
        color: $content;
      }
    }
  }
}

// in investigation detail page there is extra drop down display, need to hide this
kendo-pager-numeric-buttons,
kendo-datapager-numeric-buttons {
  select {
    &.k-dropdown-list {
      display: none !important;
    }
  }
}

kendo-popup {
  .k-searchbox {
    overflow: inherit;
  }
  .k-list-item {
    gap: 0px;
    color: $content;
    font-size: 14px;
    font-weight: 600;
    &.k-selected {
      color: $kendo-color-primary;
    }
  }
}
//checkbox for filter
.k-checkbox {
  border-radius: 2px;
  border-color: rgba(1, 24, 98, 0.24);
}
.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='white' stroke-width='3' d='M4 10h12'/%3e%3c/svg%3e");
}
.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: $kendo-color-primary;
  background-color: $kendo-color-primary;
  border-radius: 2px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='none' stroke='white' stroke-width='3' d='M4.1,12.7 9,17.6 20.3,6.3'/%3e%3c/svg%3e");
}
.apply-filter-btn {
  height: 40px;
  margin-left: -5px;
}

/* Filter Action Comment Filter, clear */
.filter-actions {
  display: flex;
  justify-content: right;
}
.actions {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

// Kendo list
.k-animation-container {
  border-radius: 5px;
}

// Dropdowns
@include cscroll;
.k-list-content {
  .k-list-ul {
    .k-list-item {
      border-top: 1px solid rgba(1, 24, 98, 0.08) !important;
      padding-block: 10px;
      font-weight: 500;
      color: $content !important;
      &:hover {
        background: rgba(1, 24, 98, 0.06);
      }
      &:not(.kendo-group-multiselect .k-list-item).k-focus,
      &.k-selected {
        position: relative;
        background: rgba(1, 24, 98, 0.06) !important;
        color: $content !important;
        &:before {
          content: "";
          background: #2196f3;
          position: absolute;
          left: 0px;
          z-index: 99;
          width: 2px;
          height: 100%;
        }
      }
    }
  }
}
.k-popup {
  &.k-list-container {
    margin-top: 5px;
  }
}
// Dropdown arrow
.k-dropdownlist {
  .k-input-inner {
    padding-left: 10px;
  }
  &.k-focus {
    .k-button-outline-base {
      .k-i-caret-alt-down {
        &:before {
          transform: rotate(180deg);
          color: $kendo-color-primary;
        }
      }
    }
  }
  .k-button-outline-base {
    padding-right: 12px;
    &:hover,
    &.k-hover {
      &:before {
        opacity: 0;
      }
    }
  }
}

// Kendo Datepicker
.k-i-calendar {
  &:before {
    content: "\f133";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #01186299;
    font-size: 14px;
  }
}
kendo-datepicker,
kendo-timepicker {
  .k-icon-button {
    border-left: 1px solid rgba(1, 24, 98, 0.1) !important;
    width: 38px;
    background: #ebedf3;
    border-radius: 0 5px 5px 0 !important;
    background-color: rgba(1, 24, 98, 0.08);
  }
}
kendo-datepicker,
kendo-timepicker,
.k-calendar {
  border-radius: 4px;
  kendo-calendar-header {
    button {
      color: $kendo-color-primary;
      // text-decoration: underline;
      text-transform: capitalize;
    }
  }
  .k-calendar-table {
    .k-calendar-td {
      border-radius: 100%;
      &:hover,
      &.k-today,
      &.k-selected,
      &.k-focus {
        .k-link {
          color: $white;
          background-color: $kendo-color-primary;
        }
      }
      &.k-range-mid {
        background: rgba(33, 150, 243, 0.25);
      }
      &.k-range-end {
        color: #fff;
      }
    }
  }
  .k-calendar-thead {
    th {
      color: rgba(1, 24, 98, 0.4);
    }
  }
  .k-calendar-navigation {
    background-color: #f8f9fa;
    box-shadow: inset -1px 0 #dee2e6;
    li {
      &:hover,
      &.k-hover,
      .k-calendar-navigation-marker,
      &.k-focus,
      &:focus {
        color: $kendo-color-primary;
      }
      .k-calendar-navigation-marker {
        font-weight: 500;
      }
    }
  }
  &.k-calendar-range  {
    .k-calendar-view {
      width: 100%;
    }
  }
}

.k-calendar-monthview {
  .k-link {
    height: 32px;
    width: 32px;
  }
}
.k-daterangepicker {
  .k-dateinput {
    &:after {
      content: "\f133";
      @include fico;
      color: #01186299;
      position: absolute;
      display: flex;
      height: 100%;
      width: 35px;
      justify-content: center;
      align-items: center;
      right: 0;
      padding: 0;
      left: auto;
      border: none;
      opacity: 1 !important;
      border-left: 1px solid rgba(1, 24, 98, 0.24);
      border-radius: 0;
    }
    &:hover {
      &:after {
        border-left: 1px solid rgba(1, 24, 98, 0.74);
      }
    }
  }
}

// tabs
.k-tabstrip {
  .k-link {
    font-weight: 600;
    font-size: 14px;
    color: rgba(1, 24, 98, 0.87) !important;
    text-transform: capitalize;
  }
  &.investigation-tabs {
    .k-tabstrip-items {
      .fa-lg {
        line-height: 20px;
      }
      .k-item {
        flex: 1;
      }
      .k-link {
        flex-direction: column;
      }
    }
  }
  &.dashboard-tabs {
    .k-content {
      padding-inline: 16px;
      padding-block: 16px;
    }
  }
  .k-content {
    padding-inline: 0px;
    padding-block: 0px;
  }
  &.profile-sidebar-tabs {
    .k-content {
      padding-inline: 16px;
      padding-block: 16px;
    }
    .fa-lg {
      line-height: 20px;
    }
    .k-item {
      flex: 1;
    }
    .k-link {
      flex-direction: column;
    }
  }
}

/* START Styling error, warning alert */
.notification-warning-kendo {
  display: flex;
  padding: 16px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #1d2338;
  border-radius: 4px;
  .icon {
    i {
      color: rgba(255, 192, 0, 1);
    }
  }
  .message {
    margin-left: 8px;
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
    }
  }
}
/* END Styling error, warning alert */

/* START Chips */

/* START base */
.k-chip-solid-base:hover,
.k-chip-solid-base.k-hover {
  background-color: rgba(1, 24, 98, 0.16);
}
.k-chip-solid-base {
  background: rgba(1, 24, 98, 0.16);
  border-radius: 16px;
  color: $content;
}
.k-chip-md {
  font-size: 12px;
  font-weight: 600;
}
.k-chip-actions {
  margin-left: 4px;
  .k-i-x-circle::before {
    content: "\f057";
    font-family: "Font Awesome 6 Free";
    font-style: normal;
    color: $content;
    font-weight: 900;
  }
}
.k-calendar-range {
  width: min-content;
}
/* END base */

/* END Chips */

.k-card-separator,
.k-card .k-separator {
  border-color: rgba(1, 24, 98, 0.08);
}

.k-grid {
  &.hours-list {
    td {
      &.k-table-td {
        padding: 12px 15px 12px 15px !important;
        &:first-child {
          padding-left: 15px !important;
        }
      }
    }
  }
}

.k-grid {
  &.user-payroll-table {
    .k-grid-header .k-table-th {
      &:first-child {
        padding-left: 0px !important;
      }
      &:last-child {
        padding-right: 0px !important;
        .k-link {
          padding-right: 15px !important;
        }
      }
    }
    td {
      &.k-table-td,
      .user-payroll-table-section {
        position: relative;
        padding: 0px 0px 0px 0px !important;
        height: 204px !important;
        vertical-align: top;
        border-top: 0px !important;
        border-left: 0 !important;
        border-bottom: 1px !important;
        border-right: 1px !important;
        border-style: solid !important;
        border-color: rgba(1, 24, 98, 0.12) !important;
        &.from-to-date {
          vertical-align: middle;
          text-align: center;
        }
        &:first-child {
          padding-left: 0px !important;
          border-left: 1px solid rgba(1, 24, 98, 0.12) !important;
        }
      }
    }
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  .page-title {
    margin-bottom: 0px;
  }
}
/* START no record css */
.no-record {
  font-weight: 800 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  padding-top: 48px;
  padding-bottom: 48px;
}
/* END no record css */

.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
  color: white;
}

/*Tabs with partition - Multi table view*/
.tab-partition {
  background: rgba(1, 24, 98, 0.04);
  padding: 15px;
}
.time-expense-tabs {
  box-shadow: 0px 3px 4px 0px #00000024;
  // filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.12));
  border-radius: 4px;
}

/* START notification Toast message */
.k-notification-group {
  right: 16px !important;
  bottom: 16px !important;
  left: 16px !important;
  z-index: 10002;
  .k-notification {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      #1d2338 !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12) !important;
    border-radius: 4px !important;
    color: #ffffff;
    &.k-notification-success {
      .k-notification-status::before {
        content: "\f058";
        font-family: "Font Awesome 6 Free";
        font-style: normal;
        color: #37b400;
        font-weight: 900;
      }
    }
    &.k-notification-error {
      .k-notification-status::before {
        content: "\f071";
        font-family: "Font Awesome 6 Free";
        font-style: normal;
        color: #f31700;
        font-weight: 900;
      }
    }
  }
}
/* END notification Toast message */

/* START report page filter */
.report-page-filters {
  margin: 16px 24px 0 24px;
}
.report-list {
  .k-grid-content-locked {
    position: relative;
    z-index: 9;
    overflow: visible;
    border-inline-end-width: 0;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      box-shadow: 0 0 6px rgb(0 0 0 / 80%);
      left: 150px;
    }
    .k-grid-table-wrap {
      position: relative;
      z-index: 1;
      background-color: #fff;
    }
  }
  .k-grid-header-locked {
    position: relative;
    z-index: 9;
    overflow: visible;
    border-inline-end-width: 0;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      box-shadow: 0 0 6px rgb(0 0 0 / 80%);
      left: 150px;
    }
  }
  .k-grid-header-table {
    position: relative;
  }
  .k-grid-header {
    border-top: 1px solid rgba(1, 24, 98, 0.08);
  }
}
.report-page-filters,
.report-matric-filters {
  .k-daterange-picker,
  .k-daterangepicker {
    grid-gap: 0px;
    gap: 0px;
  }
}
/* END report page filter */

// time and expenses in bookkeeping table
.hours-list,
.timelog-list {
  .k-grid-header {
    border-top: 1px solid rgba(1, 24, 98, 0.08);
  }
}
/* START online intake from contact tooltip */
.contact-tooltip {
  .k-tooltip {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
}
/* END online intake from contact tooltip */

/* START history log */
kendo-panelbar {
  border-radius: 4px;
  border: 1px solid rgb(227 230 239 / 87%);
}
.k-panelbar > .k-panelbar-header > .k-link,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link {
  background-color: #fff;
  color: rgba(1, 24, 98, 0.87);
}

.k-panelbar > .k-panelbar-header > .k-link,
.k-panelbar > .k-panelbar-header > .k-link.k-selected,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-selected {
  color: gba(1, 24, 98, 0.87);
  &.k-selected {
    background-color: #fff;
    color: rgba(1, 24, 98, 0.87);
    border-radius: 4px;
  }
  &:focus,
  &:active {
    background-color: #fff;
  }
}
// .history-log {
//   .k-window-title {
//     padding-top: 24px !important;
//   }
//   .k-window-titlebar-action {
//     padding: 24px 40px 0 0;
//   }
// }
kendo-panelbar-item {
  .k-link {
    border-bottom: 1px solid rgb(227 230 239 / 87%);
  }
}
/* END history log */
.k-list-group-sticky-header,
.k-list-group-item {
  background: linear-gradient(
      0deg,
      rgba(1, 24, 98, 0.08),
      rgba(1, 24, 98, 0.08)
    ),
    #ffffff;
}

kendo-scheduler {
  .k-scheduler-toolbar {
    background-color: unset;
  }
  .k-scheduler-footer {
    display: none;
    visibility: hidden;
  }
}

kendo-progressbar {
  > .k-progress-status-wrap {
    &.k-progress-end {
      .k-progress-status {
        position: absolute;
        top: 14px;
      }
    }
  }
}

/* Mobile CSS START */
.mb-48 {
  margin-bottom: 48px !important;
}
.safe-area-padding-top {
  padding-top: env(safe-area-inset-top, 16px) !important;
  padding-top: constant(safe-area-inset-top, 16px) !important;
}
.mobile-id-link {
  color: #2196f3;
  font-size: 18px !important;
  font-weight: 500 !important;
  text-decoration: underline;
}
/* Mobile CSS END */

.change-status-dialog {
  padding: 16px;
}

.k-stepper {
  .k-step-indicator{
    color: rgba(1, 24, 98, 1);
  }
  .k-step-current {
    .k-step-indicator {
      color: white;
    }
  }
  .k-step-done {
    .k-step-indicator {
      color: white;
    }
  }
  .k-step-label .k-step-text {
    white-space: break-spaces;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: rgba(1, 24, 98, 1);
  }
}
